// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './theme/custom-palette.scss' as custom-palette;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700');

@include mat.core();

$primary-palette: mat.m2-define-palette(custom-palette.$primary-palette, 500);
$accent-palette: mat.m2-define-palette(custom-palette.$accent-palette, 500);

// Define the theme object.
$sirhu-theme: mat.m2-define-light-theme(
  (
    color: (
      theme-type: light,
      primary: $primary-palette,
      accent: $accent-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--primary-background-color);
}

:root {
  @include mat.all-component-themes($sirhu-theme);
  --primary-background-color: #e1dbdb;
  --primary-table-header-text-color: #360909bd;
  --primary-table-odd-row-background-color: #f8f8f8;
  --primary-table-even-row-background-color: #eaeaea;

  --primary-color-one: #570900;
  --primary-color-two: #8a433b;
  --primary-color-three: #e2b8b3;
  --primary-color-five: #eeecec;
  --primary-color-six: #f1f1f1;

  --color-light-gray: #f8f8f8;
  --color-gray: #e4e4e4;
  --color-pink: #d87f75;
  --color-light-green: #a1caa6;
  --color-light-pink: #efdede;
  --color-very-light-gray: #eaeaea;
  --error-message: #eb0a3a;

  .mdc-checkbox {
    --mdc-checkbox-selected-focus-icon-color: #eeecec;
    --mdc-checkbox-selected-hover-icon-color: #eeecec;
    --mdc-checkbox-selected-icon-color: #eeecec;
    --mdc-checkbox-selected-pressed-icon-color: #eeecec;
    --mdc-checkbox-selected-focus-state-layer-color: #eeecec;
    --mdc-checkbox-selected-hover-state-layer-color: #eeecec;
    --mdc-checkbox-selected-pressed-state-layer-color: #eeecec;
    --mdc-checkbox-unselected-focus-state-layer-color: transparent;

    .mdc-checkbox__background {
      transform: scale(1.4);
      border-radius: 6px !important;
      background-color: #eeecec;
      border: 1px solid #37373782;
    }

    svg.mdc-checkbox__checkmark {
      transform: scale(0.7);
    }

    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      border-color: #37373782;
      background-color: initial;
    }

    .mdc-checkbox__checkmark {
      color: var(--tipografia-primaria);
    }
  }

  --primary-mat-card-background: #eeecec;

  // Aca van los estilos definitivos con sus variables:
  --tipografia-primaria: #373737;
  --acciones-c: #9bd6f3;
  --titulos-card: #570900;
  --seleccion: #d9d9d9;
  --secundario: #9d9d9d;
  --deshabilitado: #eeecec;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
span,
button,
input,
label,
tr,
td,
textarea,
th {
  font-family: 'Nunito', sans-serif !important;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  background: rgba(143, 143, 143, 0.041);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color-three) !important;
  border-radius: 22px;
  height: 30px;
}

::-webkit-scrollbar-track-piece {
  display: none;
}

.cdk-focused {
  display: none;
}

.mdc-text-field--outlined {
  background-color: #eeecec4d;
  border-radius: 6px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 0px 8px !important;

  &.mdc-text-field--disabled {
    background-color: #ededed;
  }
}

/* Color del borde */
.mdc-text-field--outlined.mdc-text-field--focused {
  border-color: var(--tipografia-primaria) !important;
}

/* Color del border cuando esta enfocado */
.mdc-text-field--outlined .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline__trailing {
  border: none !important;
}

/* Border que se divide en tres partes, este se usa cuando tenes el label en el border */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: var(--tipografia-primaria) !important;
}

/* Color del cursor */
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
  font-size: 12px !important;
  margin: 5px 0px 0px !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  height: 35px !important;
}

.mat-mdc-form-field-flex {
  height: 35px !important;
  align-items: center !important;
}

.mat-mdc-select {
  padding: 0 !important;
}

.mat-datepicker-toggle .mat-datepicker-toggle-default-icon {
  fill: #694747;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none;
}

.custom-form-control-textarea {
  width: 100%;
  border: 1px solid #0000001c;
  border-radius: 6px;
  background-color: #e4e4e445;
  color: #333;
  outline: none;
  min-height: 50px;
  resize: vertical;
  overflow: auto;
  box-sizing: border-box;
  padding: 5px;
}

textarea:disabled {
  background-color: #ededed;
}

.required-class {
  color: red;
}

// Estilos del spinner isLoading overlay ////
.cdk-overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#cdk-overlay-0 {
  top: 43vh;
}

.c-tooltip {
  font-size: 12px;
  font-weight: 700;
  white-space: normal;
  word-wrap: break-word;
}
